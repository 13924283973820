<template>
  <div class="home">
    <img alt="Vue logo" class="logo" src="../assets/Drum_logo05.png">
    <HelloWorld msg="Net test"/>
  </div>
</template>

<style>
.logo {
	max-width: 250px;
}
</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
